exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aplikace-tsx": () => import("./../../../src/pages/aplikace.tsx" /* webpackChunkName: "component---src-pages-aplikace-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-en-contacts-tsx": () => import("./../../../src/pages/en/contacts.tsx" /* webpackChunkName: "component---src-pages-en-contacts-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-projekt-tsx": () => import("./../../../src/pages/projekt.tsx" /* webpackChunkName: "component---src-pages-projekt-tsx" */),
  "component---src-pages-resitele-tsx": () => import("./../../../src/pages/resitele.tsx" /* webpackChunkName: "component---src-pages-resitele-tsx" */),
  "component---src-pages-vysledky-tsx": () => import("./../../../src/pages/vysledky.tsx" /* webpackChunkName: "component---src-pages-vysledky-tsx" */)
}

